<template>
  <Main>
    <template v-slot:content>
      <div class="order container">
        <div v-if="features.length && order" class="order__container">
          <div v-if="!is_trial" class="order__list">
            <div
              @click="addFeatures(item.name)"
              v-for="item in features"
              :key="item.name"
              class="order__list-item"
            >
              <div class="order__check-container">
                <div v-if="choseFeatures.includes(item.name)" class="order__list-item-check active">
                  <CheckStyleIcon />
                </div>
                <div v-else class="order__list-item-check"></div>
              </div>
              <div class="order__list-item-desc">
                <div class="order__list-item-title">{{ item.title }}</div>
                <div class="order__list-item-text">{{ item.description }}</div>
              </div>
            </div>
          </div>
          <div class="order__bottom-container">
            <div class="order__back desc">
              <div @click="handleBack" class="order__back-button">{{ $t("message.Back") }}</div>
            </div>
            <div class="order__total-container">
              <div class="order__total">
                <div class="order__total-text">{{ $t("message.Amount") }}</div>
                <div v-if="order" class="order__total-count">{{ order.subtotal_formatted }}</div>
              </div>
              <div class="order__total">
                <div class="order__total-text">{{ $t("message.VAT") }}</div>
                <div v-if="order" class="order__total-count">{{ order.tax_amount_formatted }}</div>
              </div>
              <div class="order__total">
                <div class="order__total-text">{{ $t("message.In_total") }}</div>
                <div v-if="order" class="order__total-count">{{ order.price_formatted }}</div>
              </div>
              <div class="order__back-button-container">
                <div class="order__back mob">
                  <div @click="handleBack" class="order__back-button">{{ $t("message.Back") }}</div>
                </div>
                <div @click="handleFinishOrder" v-if="!is_trial" class="order__to-pay">
                  {{ $t("message.To_Pay") }}
                </div>
                <div @click="handleFinishOrder" v-else class="order__to-pay">
                  {{ $t("message.Next_Step") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer></template>
  </Main>
</template>

<script>
import baseApi from "~/api/base";
import projectApi from "~/api/project";
import ROUTE from "~/constants/routes";

import Main from "~/templates/Main.vue";

import CheckStyleIcon from "~/assets/images/icons/check-style.svg";

export default {
  data() {
    return {
      order: null,
      features: [],
      choseFeatures: [],
      is_trial: false,
    };
  },
  mounted() {
    this.getOrder();
  },
  components: {
    Main,
    CheckStyleIcon,
  },
  methods: {
    getOrder() {
      const url = `/${this.$route.params.id}`;
      try {
        projectApi
          .getOrderItem(url)
          .then((res) => {
            this.order = res;
            if (this.order.items[0].project.service_type.value == "trial") {
              this.is_trial = true;
            }

            this.getOrderFeaturesList(this.order.id);

            this.choseFeatures = [];

            if (this.order.features.length > 0) {
              for (let i = 0; this.order.features.length > i; i++) {
                this.choseFeatures.push(this.order.features[i].name);
              }
            }
          })
          .catch((error) => {})
          .finally(() => {
            this.loading = false;
          });
      } catch (error) {
        console.log(error);
      }
    },

    getOrderFeaturesList(id) {
      try {
        baseApi
          .getOrderFeaturesList(id)
          .then((res) => {
            this.features = res;
          })
          .catch((error) => {})
          .finally(() => {
            this.loading = false;
          });
      } catch (error) {
        console.log(error);
      }
    },

    handleBack() {
      if (this.order.items.length > 1) {
        let url = `?projects=`;
        for (let a = 0; this.order.items.length > a; a++) {
          if (this.order.items.length == a + 1) {
            url += `${this.order.items[a].project.id}`;
          } else {
            url += `${this.order.items[a].project.id},`;
          }
        }

        this.$router.push({
          path: ROUTE.PROJECT_ROOMS.replace(
            ":id",
            this.order.items[this.order.items.length - 1].project.id + url,
          ),
        });
      } else {
        this.$router.push({
          path: ROUTE.PROJECT_ROOMS.replace(":id", this.order.items[0].project.id),
        });
      }
    },
    addFeatures(name) {
      const url = `/${this.$route.params.id}`;
      const data = {
        features: [],
      };

      for (let i = 0; this.choseFeatures.length > i; i++) {
        data.features.push(this.choseFeatures[i]);
      }

      if (data.features.includes(name)) {
        const index = data.features.indexOf(name);

        if (index > -1) {
          data.features.splice(index, 1);
        }
      } else {
        data.features.push(name);
      }

      console.log(data);

      try {
        projectApi
          .setOrderItems(url, data)
          .then((res) => {
            this.getOrder();
          })
          .catch((error) => {})
          .finally(() => {
            this.loading = false;
          });
      } catch (error) {
        console.log(error);
      }
    },
    handleFinishOrder() {
      this.$router.push({
        path: ROUTE.ORDERS_SUCCESS.replace(":id", this.$route.params.id),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.order {
  display: flex;
  justify-content: center;

  .desc {
    display: block;
  }

  .mob {
    display: none;
  }

  &__container {
    margin-top: 30px;
    min-width: 900px;
    max-width: 1000px;
    padding: 30px;
    border-radius: 15px;
    border: 1px #d7d9dd;
    background: #f9f9f9;
  }

  &__list-item {
    display: flex;
    align-items: center;
    padding-bottom: 25px;
    margin: 10px 0;
    border-bottom: 1px solid #d7d9dd;
  }

  &__check-container {
    margin-right: 15px;
  }

  &__list-item-check {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: 1px solid #adadad;
    background: #fff;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2) inset;
    cursor: pointer;

    svg {
      display: none;
    }

    &.active {
      background: #000;

      svg {
        display: block;
      }
    }
  }

  &__list-item-title {
    font-family: "Mirador";
    font-size: 24px;
    padding-bottom: 5px;
  }

  &__list-item-text {
    color: #191919;
  }

  &__bottom-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-top: 30px;
  }

  &__back-button {
    width: 215px;
    padding: 15px;
    border-radius: 8px;
    background: #dadee7;
    color: #191919;
    display: flex;
    justify-content: center;
    cursor: pointer;

    &:hover {
      background: #b3b5b9;
    }
  }

  &__total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
  }

  &__total-text,
  &__total-count {
    color: #191919;
    font-size: 20px;
    font-weight: 600;
  }

  &__total-text {
    font-size: 18px;
  }

  &__to-pay {
    width: 215px;
    padding: 15px;
    border-radius: 8px;
    background: #191919;
    color: #fff;
    display: flex;
    justify-content: center;
    cursor: pointer;

    &:hover {
      background: #000;
    }
  }

  @media (max-width: 1350px) {
    &__container {
      min-width: 750px;
      max-width: 750px;
    }
    &__list-item-title {
      font-size: 20px;
    }
  }

  @media (max-width: 991px) {
    &__container {
      min-width: 90%;
      max-width: 90%;
      padding: 20px;
    }
  }

  @media (max-width: 568px) {
    &__container {
      min-width: 100%;
      max-width: 100%;
    }
    &__list-item-title {
      font-size: 18px;
    }

    .desc {
      display: none;
    }

    .mob {
      display: block;
    }

    &__total-container {
      width: 100%;
    }

    &__back-button-container {
      display: flex;
      justify-content: space-between;
    }

    &__back-button,
    &__to-pay {
      width: fit-content;
      padding: 15px 40px;
    }
  }
}
</style>
